import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box, Flex } from 'grid-styled'
import CTA from '../../components/CTA'
import GalleryContainer from '../../components/GalleryContainer'
import GalleryImage from '../../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
} from '..'
import Form from '../../components/Form'
import { Text, GallerySection, Content } from '../../components/styles'
import Button from '../../components/Button'
import ContentCard from '../../components/ContentCard'
import Hero from '../../components/Hero'
import { Video } from '../../components/procedures'

export default ({ data }) => (
  <Layout header="dark">
    <Helmet>
      <title>Skillion Non-Insulated Patio | Just Patios</title>
      <meta
        name="description"
        content="Professionally designd and built skillion non-insulated patios. At Just Patios we customise your lifestyle. Contact us today for an obligation free design & quote."
      />
    </Helmet>

    <Content style={{ paddingTop: 150 }}>
      <h1>Flat / Skillion Non-Insulated Patio</h1>

      <h4>
        Your custom outside area should create a feeling of space and openness,
        all whilst seamlessly pairing with your existing roof, gutters, and
        exterior.
      </h4>
      <p>
        This is one of our most popular designs creating extra space for your
        outside area. Long spanning Colourbond and Colourbond equivalent roofing
        eliminates the need for extra beams & rafters, and provides you the
        ceiling height you require. Our roofing comes in a range of colours and
        finishes to blend in with your existing gutters & fascia, complimenting
        your home.
      </p>

      <Link to="/contact-us#form">
        <Button inverted={true}>Request Your Free Quote Today!</Button>
      </Link>
    </Content>

    <GalleryContainer>
      {data.gallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          const arr = x.node.childImageSharp.fixed.src.split('/')
          const number = parseInt(arr[arr.length - 1].split('.')[0])
          const arr2 = y.node.childImageSharp.fixed.src.split('/')
          const number2 = parseInt(arr2[arr2.length - 1].split('.')[0])

          return number < number2 ? -1 : 1
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return false
          }
          const arr = image.node.childImageSharp.fixed.src.split('/')
          const name = arr[arr.length - 1].split('.')[0]
          if (name === 'cover') {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fixed={image.node.childImageSharp.fixed} />
            </GalleryImage>
          )
        })}
    </GalleryContainer>

    <VideoContainer style={{ marginTop: -100 }}>
      <Video
        videoId="yqTGp7gMtng"
        opts={{
          height: '576',
          width: '1024',
          playerVars: {
            color: 'white',
            rel: 0,
          },
        }}
      />
    </VideoContainer>

    <AboutContact style={{ margin: '0 auto', padding: 0 }} />
  </Layout>
)

export const query = graphql`
  query SkillionNonInsulatedQuery {
    gallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/patios/non-insulated" } }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
